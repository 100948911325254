<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="160px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                        <project-select v-model="formData.projectId"> </project-select>
                    </el-form-item>
                    <el-form-item label="活动名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="collectionId" label="需要持有的藏品">
                        <el-form-item prop="collectionId" :rules="{ required: true, message: '请选择藏品' }">
                            <collection-search v-model="formData.collectionId" :projectId="formData.projectId">
                            </collection-search>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item prop="targetCollectionIdList" label="可优先购买的藏品">
                        <el-form-item prop="targetCollectionIdList">
                            <collection-search
                                :isMultiple="true"
                                v-model="formData.targetCollectionIdList"
                                :projectId="formData.projectId"
                            >
                            </collection-search>
                            <div class="form_tip">不选择默认可优先购买所有藏品</div>
                        </el-form-item>
                    </el-form-item>

                    <el-form-item prop="advancePurchaseTime" label="时间">
                        <el-input-number v-model="formData.advancePurchaseTime" type="number"> </el-input-number>
                        <div class="form_tip">提前购买时间（小时），示例：1小时 = 1 24小时 = 24；暂不支持小数</div>
                    </el-form-item>

                    <el-form-item prop="advancePurchaseCount" label="次数">
                        <el-input-number v-model="formData.advancePurchaseCount" type="number"></el-input-number>
                        <div class="form_tip">可提前购买次数</div>
                    </el-form-item>

                    <el-form-item label="凭证类型" prop="itemId">
                        <el-select v-model="formData.itemId">
                            <!-- <el-option :value="0" label="永久性优先购凭证"></el-option> -->
                            <el-option :value="1" label="消耗性全局优先购凭证"></el-option>
                            <el-option :value="2" label="消耗性指定藏品优先购凭证"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="text" label="说明">
                        <el-input v-model="formData.text" type="textarea" rows="5"></el-input>
                    </el-form-item>

                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'PreemptionEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('/activityPriorityDocument/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId
            },
            rules: {
                collectionId: [
                    {
                        required: true,
                        message: '请输入藏品ID',
                        trigger: 'blur'
                    }
                ],
                itemId: [
                    {
                        required: true,
                        message: '请选择凭证类型',
                        trigger: 'blur'
                    }
                ],
                advancePurchaseTime: [
                    {
                        required: true,
                        message: '请输入提前购买时间（小时）',
                        trigger: 'blur'
                    }
                ],
                advancePurchaseCount: [
                    {
                        required: true,
                        message: '请输入可提前购买次数',
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            }
        };
    },
    methods: {
        // 保存验证
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        // 新增 / 编辑
        submit() {
            let data = { ...this.formData };
            console.log(data);

            this.saving = true;
            this.$http
                .post('/activityPriorityDocument/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityPriorityDocument/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
