<template>
  <div class="edit-view">
    <page-title>
      <el-button @click="$router.go(-1)"
                 :disabled="saving">取消</el-button>
      <el-button @click="onDelete"
                 :disabled="saving"
                 type="danger"
                 v-if="formData.id"> 删除 </el-button>
      <el-button @click="onSave"
                 :loading="saving"
                 :disabled="!canEdit"
                 type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form :model="formData"
                 :rules="rules"
                 ref="form"
                 label-width="160px"
                 label-position="right"
                 size="small"
                 style="max-width: 1200px;">
          <el-form-item label="项目"
                        prop="projectId">
            <project-select v-model="formData.projectId"> </project-select>
          </el-form-item>
          <el-form-item prop="name"
                        label="活动名称">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>

          <!-- <el-form-item
            prop="grantCount"
            label="已发放盒子数量"
          >
            <el-input-number
              v-model="formData.grantCount"
              type="number"
              :min="1"
            > </el-input-number>
          </el-form-item>

          <el-form-item
            prop="boxSum"
            label="盒子总数"
          >
            <el-input-number
              v-model="formData.boxSum"
              type="number"
              :min="1"
            ></el-input-number>
          </el-form-item> -->
          <el-form :model="{ boxList }"
                   ref="formlist"
                   label-width="160px"
                   label-position="right"
                   size="small"
                   class="formlist"
                   style="max-width: 1200px">

            <el-form-item v-for="(item, index) in boxList"
                          :key="index"
                          :rules="[{ required: true, message: '请配置惊喜盒子', trigger: 'blur' }]"
                          :prop="`boxList[${index}].type`"
                          :label="'物品' + (index+1)">
              <el-row :gutter="24">
                <el-col :span="5">

                  <el-select v-model="item.type"
                             filterable
                             placeholder="盒子类型"
                             style="width: 100%"
                             @change="changType(item)"
                             :disabled="!canEdit">
                    <el-option v-for="row in typeList"
                               :label="row.label"
                               :value="row.value"
                               :key="row.value">
                      <span style="float: left">{{ row.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">#{{ row.value }}</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6"
                        v-if="item.type == '0' ">
                  <el-form-item :prop="`boxList[${index}].collectionId`"
                                :rules="{ required: true, message: '请选择藏品' }">
                    <collection-search v-model="item.collectionId"
                                       :projectId="formData.projectId">
                    </collection-search>
                  </el-form-item>
                  <!-- <collection-search
                    v-model="item.collectionId"
                    :projectId="formData.projectId"
                  >
                  </collection-search> -->
                </el-col>
                <el-col :span="6">
                  <el-input-number type="number"
                                   placeholder="发行数量"
                                   v-model="item.count"
                                   :disabled="!canEdit"
                                   @change="changeCount(item)"
                                   :min="1"></el-input-number>
                  <div class="form_tip">发行数量</div>

                </el-col>
                <el-col :span="3"
                        v-if="inShow">
                  <el-input-number type="number"
                                   placeholder="库存"
                                   v-model="item.stock = item.count"
                                   :disabled="!canEdit"
                                   :min="1"></el-input-number>
                  <div class="form_tip">剩余库存</div>

                </el-col>
                <el-col :span="3"
                        v-if="item.type == '1'||item.type == '2'">
                  <el-upload class="upload-demo"
                             :action="uploadUrl"
                             multiple
                             :limit="1"
                             :before-remove="(response,file,fileList) =>beforeRemove(response,file,fileList,item)"
                             :on-success="(response,file,fileList) =>handleSuccess(response,file,fileList,item)">
                    <el-button size="small"
                               type="primary"
                               :disabled="!canEdit">上传图片</el-button>
                  </el-upload>
                </el-col>
                <el-col :span="3">
                  <el-input placeholder="备注"
                            v-model="item.remarks"></el-input>
                </el-col>
                <el-col :span="3">
                  <el-button :disabled="!canEdit"
                             v-if="boxList.length >=2"
                             @click.prevent="removeItem(item,boxList)">删除</el-button>
                </el-col>

              </el-row>
            </el-form-item>

          </el-form>

          <el-form-item class="form-submit">
            <el-button :disabled="!canEdit"
                       @click="additem">新增惊喜盒子</el-button>
            <el-button @click="onSave"
                       :disabled="!canEdit"
                       :loading="saving"
                       type="primary"> 保存 </el-button>
            <el-button @click="onDelete"
                       :disabled="saving"
                       type="danger"
                       v-if="formData.id">
              删除
            </el-button>
            <el-button @click="$router.go(-1)"
                       :disabled="saving">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import resolveUrl from 'resolve-url';
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'PreemptionEdit',
    data() {
        return {
            inShow: false,
            uploadUrl: '',
            fileList: [],
            typeList: [
                {
                    value: 0,
                    label: '藏品/盲盒'
                },
                {
                    value: 1,
                    label: '实物（手动发放)'
                },
                {
                    value: 2,
                    label: '空盒子'
                }
            ],
            boxList: [
                {
                    type: '',
                    collectionId: '',
                    count: 1,
                    stock: 1,
                    picUrl: ''
                }
            ],
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                boxSum: 1,
                grantCount: 1,
                name: '',
                properties: []
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            }
        };
    },
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'upload/file');
        if (this.$route.query.id) {
            this.$http
                .get('/activitySurpriseBox/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    this.boxList = res.properties;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    methods: {
        handleSuccess(response, file, fileList, item) {
            return (item.picUrl = response);
        },
        beforeRemove(response, file, fileList, item) {
            this.$confirm(`确定移除上传的图片？`)
                .then(() => {
                    return (item.picUrl = '');
                })
                .catch(e => {});
        },
        removeItem(item, that) {
            var index = that.indexOf(item);
            if (index !== -1) {
                that.splice(index, 1);
            }
        },
        // 保存验证
        onSave() {
            let list = [];
            list.push(this.submitForm('form'), this.submitForm('formlist'));
            Promise.all(list).then(() => {
                this.submit();
            });
        },
        // 表单集合校验
        submitForm(formName) {
            return new Promise((resolve, reject) => {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        resolve();
                    } else reject();
                });
            });
        },
        changType(item) {
            console.log(item, 'item');
        },
        changeCount(row) {
            return (row.stock = row.count);
        },
        additem() {
            this.boxList.push({
                type: '',
                collectionId: '',
                count: 1,
                stock: 1,
                picUrl: '',
                remarks: ''
            });
        },
        // 上传图片
        // updateImg() {},
        // 新增 / 编辑
        submit() {
            let data = {
                ...this.formData,
                stock: this.formData.count,
                properties: this.boxList
            };
            this.saving = true;
            this.$http
                .post('/activitySurpriseBox/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activitySurpriseBox/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
.formlist /deep/.el-input-number--small {
    width: 200px;
}
</style>
